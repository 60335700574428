// xxx.js 日语文件
export default {
    app: {
        button:{
            logout: 'サインアウト',
            submit: '提出する',
            confirm: '確認する',
            cancel: 'キャンセル',
            forbidden:'禁断',
            enable:'有効にする',
            dialog_confirm_delete_title:'削除を確認しますか？',
            userpopover_delete_now:'すぐに削除',
            userpopover_change_password:'パスワードの変更',
            userpopover_delete_user:'ユーザーの削除',
            userpopover_forbidden:'ユーザーを無効にする',
        },
        title: 'ADS管理システム',
        home: '表紙',
        homenotic: '有効期限が切れたデバイスの数量：',
        user_manage: 'ユーザー',
        mac_manage: 'デバイス',
        ad_manage: 'ADS',
        fie_manage: 'ファイル',
        refresh_loosing_text: '放してリフレッシュ',
        refresh_loading_text: 'アップロード中',
        required_username: 'ユーザー名の入力',
        min_username: '{t}文字以上のユーザー名を入力してください',
        placeholder_username: 'ユーザー名',
        add_username: 'ユーザーを追加する',
        required_password: 'パスワードを入力してください',
        min_password: '{t}文字以上のパスワードを入力してください',
        placeholder_password: 'パスワード',
        required_confirm_password: 'パスワードを入力してください',
        placeholder_confirm_password: '確認する',
        two_password:'パスワードが異なります',
        edit_password:'パスワードの編集',
        required_yzm: '確認コードを入力してください',
        placeholder_yzm: '検証コード',
        user_count: 'ユーザー統計',
        mac_count: 'デバイスの統計情報',
        ad_count: 'AD統計の表示',
        resources_count: 'リソース統計',
        create_time:'作成日',
        not_more:'もうない',
        search:'キーワードを入力してください',
        search_user:'ユーザー名の入力',
        search_mac:'デバイスの Mac 番号を入力してください',
        checkbox_select_max:'すでに最大規模',
        userpopover_delete_user_confirm:'({u}) を削除することを確認しますか?',
        userpopover_placeholder_forbidden_note:'備考を入力してください',
        user_list_button_dialog_title:'ボタンのコントロール',
        user_list_button_dialog_note:'スイッチを切り替えるかどうか?',
        ad_list_tab_list_total:'見つかった合計広告数は {t} です',
        ad_list_tab_list_card_title:'合計 {m} 枚のビデオと {i} 枚の写真',
        ad_list_tab_list_card_type:'タイプ：{t}',
        ad_list_tab_list_card_play_type:'プレイモード：{t} {y}',
        ad_list_tab_list_card_mac:'合計 {t} 台のデバイス',
        ad_list_tab_list_card_day:'発売日：{t}',
        ad_show:'デュアルスクリーンの異なるコンテンツ',
        ad_not_show:'デュアルスクリーン同じコンテンツ',
        ad_host_ping:'画面1',
        ad_deputy_ping:'画面2',
        ad_complete_ping:'全画面表示',
        ad_two_screens:'2 つの分割画面',
        ad_three_screen:'3 つの分割画面',
        ad_divide_ping:'画面を分割',
        ad_class_ping_vertical:'縦画面',
        ad_class_ping_horizontal:'横画面',
        ad_sort:'プレイリストの並べ替え',
        file_del:'消去',
        file_tab_type_1:'全画面（写真）',
        file_tab_type_2:'ビデオ',
        file_tab_type_3:'写真',
        file_tab_vertical_type_3:'3 つの分割画面 (上の図)',
        file_tab_vertical_type_4:'3 つの分割画面 (下の図)',
        file_tab_vertical_type_5:'2 つの分割画面 (下の図)',
        file_tab_horizontal_type_3:'3 分割画面 (左の図)',
        file_tab_horizontal_type_4:'3 分割画面 (右の図)',
        file_upload:'ファイルをアップロードする',
        file_upload_user:'ユーザーをアップロードする',
        file_upload_ing:'アップロード中...',
        file_upload_success:'アップロードに成功しました',
        file_upload_fail:'アップロードに失敗しました',
        file_upload_confirm:'アップロードの確認',
        file_upload_max_count:'毎回最大 {t} ファイルをアップロードします',
        file_upload_size:'各ファイル最大 {t}M',
        file_upload_pixel_size:'推奨サイズ:',
        file_upload_format:'ファイル形式:{t}',
        file_upload_format_error:'ファイルは {t} 形式でアップロードしてください',
        file_upload_filename:'ファイル名に特殊記号を含めることはできません',
        file_release:'リリースファイル',
        file_release_select:'ファイルを選ぶ',
        file_release_select_num:'選択された合計 {t}',
        file_release_form_team_id_label:'画面の種類',
        file_release_form_ping_label:'選択画面',
        file_release_form_palytype_label:'プレイモード',
        file_release_form_display_true:'はい',
        file_release_form_display_false:'いいえ',
        file_release_form_button_add_video:'ビデオを追加',
        file_release_form_button_add_img:'写真を追加',
        file_release_form_button_add:'追加ファイル',
        file_release_form_placeholder_video:'ビデオを選択してください',
        file_release_form_placeholder_img:'写真を選択してください',
        file_release_confirm:'リリースを確認する',
        mac_confirm_batch_delete:'消去',
        mac_confirm_batch_replace_user:'ユーザーを置き換える',
        mac_confirm_batch_set_up_expire:'有効な日付を設定する',
        mac_title_click_select_user:'ユーザーの選択',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'オンライン',
        mac_list_mac_status_lx:'オフライン',
        mac_list_mac_load_status_done:'ダウンロードが成功しました',
        mac_list_mac_load_status_waiting:'ダウンロードを待っています',
        mac_list_mac_expire:'期限切れ',
        mac_list_mac_expire_time:'有効期限',
        mac_list_mac_update_user:'更新オペレータ',
        mac_list_export_name:'デバイス MAC エクスポート {t}.xls',
        mac_import_template_name:'バッチインポート Devices.xls のテンプレート',
        mac_import_template_button_name:'デバイスの一括追加',
        mac_menu_button_clean:'片付ける',
        mac_menu_button_batch_delete:'消去',
        mac_menu_button_batch_replace_user:'ユーザーを置き換える',
        mac_menu_button_batch_replace_user_title:'ユーザーに移動',
        mac_menu_button_download_template:'テンプレートをダウンロード',
        mac_menu_button_set_expire:'有効な日付を設定する',
        mac_menu_button_set_expire_select_user:'ユーザーの選択',
        mac_menu_button_set_expire_select_device:'デバイスの選択',
        mac_menu_button_set_expire_select_day:'日付を選択してください',
        mac_scancode_title:'スキャンコード',
        mac_scancode_upload_title:'QRコードをアップロードする',
        mac_scancode_result:'QRコードをボックスに入れると自動的にスキャンできる',
        mac_scancode_identify:'認識に誤りがある',
        user_register_page_title:'登録ユーザー',
        user_register_download_file_name:'登録招待コード.xls',
        user_register_page_code_error:'招待コードが無効です'
    }
}