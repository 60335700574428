import { createApp } from 'vue'
import App from '@/App.vue'
// 1. 引入组件样式
import 'vant/lib/index.css';
import './assets/css/maxwidth.css'
// 1. 引入你需要的组件
import {
    Badge,
    BackTop,
    Button,
    Card,
    Cell,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    ContactCard,
    Col,
    Row,
    DatePicker,
    Divider,
    Dialog,
    DropdownMenu,
    DropdownItem,
    Grid,
    GridItem,
    Form,
    Field,
    Popover,
    Popup,
    PullRefresh,
    Lazyload,
    List,
    Icon,
    Image as VanImage,
    NavBar,
    NoticeBar,
    RadioGroup,
    Radio,
    Search,
    Space,
    Swipe,
    SwipeItem,
    Switch,
    Step,
    Steps,
    Sticky,
    Tabbar,
    Tab,
    Tabs,
    Tag,
    TabbarItem,
    Uploader,
    Watermark
} from 'vant';
import store from '@/store'
import router from '@/router';
import i18n from '@/lang';
createApp(App)
    .use(Badge)
    .use(BackTop)
    .use(Button)
    .use(Card)
    .use(Cell)
    .use(CellGroup)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(ContactCard)
    .use(Col)
    .use(Row)
    .use(DatePicker)
    .use(Divider)
    .use(Dialog)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Grid)
    .use(GridItem)
    .use(Field)
    .use(Form)
    .use(Popover)
    .use(Popup)
    .use(PullRefresh)
    .use(Lazyload, {
        lazyComponent: true
    })
    .use(List)
    .use(Icon)
    .use(i18n)
    .use(NavBar)
    .use(NoticeBar)
    .use(router)
    .use(RadioGroup)
    .use(Radio)
    .use(Search)
    .use(Space)
    .use(Swipe)
    .use(SwipeItem)
    .use(Switch)
    .use(Step)
    .use(Steps)
    .use(store)
    .use(Sticky)
    .use(Tabbar)
    .use(Tab)
    .use(Tabs)
    .use(Tag)
    .use(TabbarItem)
    .use(Uploader)
    .use(VanImage)
    .use(Watermark)
    .mount('#app')