import { createStore } from 'vuex'
import {session} from '@/utils/storage.js'
const roleKey = 'fyd-Role'
const superiorKey = 'fyd-Superior'
const currentKey = 'fyd-Current'
export default createStore({
  state: {
    role: session.get(roleKey),
    superior: session.get(superiorKey),
    current: session.get(currentKey),
  },
  getters: {
    getrole: state => state.role,
    getsuperior: state => state.superior,
    getcurrent: state => state.current
  },
  mutations: {
    setrole(state,value){
      state.role = value
      session.set(roleKey, value)
    },
    setsuperior(state,value){
      state.superior = value
      session.set(superiorKey, value)
    },
    setcurrent(state,value){
      state.current = value
      session.set(currentKey, value)
    }
  },
  actions: {
  },
  modules: {
  },
})