import axios from 'axios'
import {getToken, removeToken, setToken} from '@/utils/token'
import { showFailToast } from 'vant';
import store from "@/store";
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if(response.headers.has('token')){
      let token = response.headers.get('token')
      let expire = response.headers.get('expires_in')
      setToken(token,expire)
    }
    chankCode(response.data.code)
    let role = false
    if(response.headers.has('role')){
      var rerole = response.headers.get('role')
      if(rerole === 'leading'){
        role = true
      }
    }
    store.commit("setrole",role)
    if(response.headers.has('superior')){
      let superior = response.headers.get('superior')
      store.commit("setsuperior",superior)
    }
    if(response.headers.has('current')){
      let current = response.headers.get('current')
      store.commit("setcurrent",current)
    }
    return response.data
  },
  error => {
    if(error.response.data.code){
      chankCode(error.response.data.code)
      showFailToast({
        message: error.response.data.message || error.message,
        wordBreak: 'break-word',
      })
    }
    console.log('error:' + error.response.data) // for debug
    return error.response
  }
)

// 统一处理 code
export function chankCode(code){
  switch (code) {
    case 401:
      removeToken()
      router.push("/login")
      break;
    default:
      break;
  }
}

export default service
