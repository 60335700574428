import { createI18n } from 'vue-i18n';
import ZH from '@/lang/zh_cn';
import EN from '@/lang/en_us';
import DE from '@/lang/de';
import ES from '@/lang/es';
import FR from '@/lang/fr';
import AR from '@/lang/ar';
import JA from '@/lang/ja';
import KO from '@/lang/ko';
import PT from '@/lang/pt';
import RU from '@/lang/ru';
import TR from '@/lang/tr';
import VI from '@/lang/vi';
const messages = {
    zh: { ...ZH  },// 中文
    en: { ...EN  },// 英文
    de: { ...DE  },// 德语
    es: { ...ES  },// 西班牙
    fr: { ...FR  },// 法语
    ar: { ...AR  },// 阿拉伯
    ja: { ...JA  },// 日语
    ko: { ...KO  },// 韩语
    pt: { ...PT  },// 葡萄牙
    ru: { ...RU  },// 俄语
    tr: { ...TR  },// 土耳其
    vi: { ...VI  },// 越南语
};

//得到默认使用的语言
const getDefaultLang = () => {
    //用户指定了默认语言时，使用用户指定的
    if (localStorage.getItem('lang') != null) {
        return localStorage.getItem('lang');
    } else {
        //用户未指定时，根据游览器选择:
        let lang = 'en'
        if(navigator.language){
            switch (navigator.language){
                case 'zh-CN': // 中文
                    lang = 'zh';
                    break;
                case 'de-DE': // 德语（德国）
                case 'de-AT': // 德语（奥地利）
                case 'de-LI': // 德语（列支敦士登）
                case 'de-CH': // 德语（瑞士）
                    lang = 'de';
                    break;
                default:
                    lang = navigator.language
                    break;
            }
        }
        // 当前语言不是预定义语言将默认为英语
        if(!messages.hasOwnProperty(lang)){
            lang = 'en'
        }
        return lang;
    }
}

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: getDefaultLang(),
    messages
});
export default i18n;