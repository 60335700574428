import request from '@/utils/request'
// 登录
export function login(data) {
    return request({
        url: 'login',
        method: 'post',
        data
    })
}
// 验证码获取
export function captcha(data) {
    return request({
        url: 'captcha',
        method: 'post',
        data
    })
}
// 首页
export function index(data) {
    return request({
        url: '/',
        method: 'post',
        data
    })
}
// 退出登录
export function logout() {
    return request({
        url: 'logout',
        method: 'post'
    })
}
// 注册用户(后台添加)
export function register(data) {
    return request({
        url: 'register',
        method: 'post',
        data
    })
}

// 注册用户(邀请注册)
export function selfregister(data) {
    return request({
        url: 'selfregister',
        method: 'post',
        data
    })
}

// 邀请注册用户(检验注册码是否可用)
export function checkregistercodeexpire(data) {
    return request({
        url: 'checkregistercode',
        method: 'post',
        data
    })
}

// 校验token是否有效
export function checktoken() {
    return request({
        url: 'checktoken',
        method: 'post'
    })
}

// 下载注册邀请码
export function InviteRegisterCode(data) {
    return request({
        url: 'inviteregistercode',
        method: 'get',
        responseType: "blob",
        params:data
    })
}