// 引入
import { storage } from '@/utils/storage.js'

const TokenKey = 'fyd-Token'

export function getToken() {
  return storage.get(TokenKey)
}

export function setToken(token,expire) {
  return storage.set(TokenKey, token,expire)
}

export function removeToken() {
  return storage.remove(TokenKey)
}