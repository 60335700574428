// xxx.js 韩语文件
export default {
    app: {
        button:{
            logout: '로그아웃',
            submit: '제출하다',
            confirm: '확인하다',
            cancel: '취소',
            forbidden:'금지됨',
            enable:'할 수 있게 하다',
            dialog_confirm_delete_title:'삭제하시겠습니까?',
            userpopover_delete_now:'즉시 삭제',
            userpopover_change_password:'비밀번호 수정',
            userpopover_delete_user:'사용자 삭제',
            userpopover_forbidden:'사용자 비활성화',
        },
        title: 'ADS 관리 시스템',
        home: '첫 장',
        homenotic: '만료된 장치 수량:',
        user_manage: '사용자',
        mac_manage: '장치',
        ad_manage: '광고',
        fie_manage: '파일',
        refresh_loosing_text: '새로고침하려면 손을 떼세요',
        refresh_loading_text: '업로드 중',
        required_username: '사용자 이름 입력',
        min_username: '{t}자 이상의 사용자 이름을 입력하세요.',
        placeholder_username: '사용자 이름',
        add_username: '사용자 추가',
        required_password: '비밀번호를 입력해주세요',
        min_password: '{t}자 이상의 비밀번호를 입력하세요.',
        placeholder_password: '비밀번호',
        required_confirm_password: '비밀번호를 입력해주세요',
        placeholder_confirm_password: '확인하다',
        two_password:'비밀번호가 다릅니다.',
        edit_password:'비밀번호 편집',
        required_yzm: '인증번호를 입력해주세요',
        placeholder_yzm: '확인 코드',
        user_count: '사용자 통계',
        mac_count: '장치 통계',
        ad_count: '광고 통계 표시',
        resources_count: '자원 통계',
        create_time:'생산 일',
        not_more:'더 이상은 없어',
        search:'키워드를 입력해주세요',
        search_user:'사용자 이름 입력',
        search_mac:'장치 Mac 번호를 입력하세요.',
        checkbox_select_max:'이미 최대 규모',
        userpopover_delete_user_confirm:'삭제({u})를 확인하시겠습니까?',
        userpopover_placeholder_forbidden_note:'비고를 입력해주세요',
        user_list_button_dialog_title:'버튼 컨트롤',
        user_list_button_dialog_note:'스위치 전환 여부?',
        ad_list_tab_list_total:'총 발견량 {t} AD',
        ad_list_tab_list_card_title:'총 {m} 비디오 및 {i} 사진',
        ad_list_tab_list_card_type:'유형：{t}',
        ad_list_tab_list_card_play_type:'플레이 모드：{t} {y}',
        ad_list_tab_list_card_mac:'총 {t} 장치',
        ad_list_tab_list_card_day:'출시 날짜: {t}',
        ad_show:'듀얼 스크린 다양한 콘텐츠',
        ad_not_show:'듀얼 스크린 동일 내용',
        ad_host_ping:'화면 1',
        ad_deputy_ping:'화면 2',
        ad_complete_ping:'전체 화면',
        ad_two_screens:'두 개의 분할 화면',
        ad_three_screen:'3개의 분할 화면',
        ad_divide_ping:'분할 화면',
        ad_class_ping_vertical:'수직 화면',
        ad_class_ping_horizontal:'수평 화면',
        ad_sort:'플레이리스트를 정렬하라',
        file_del:'삭제',
        file_tab_type_1:'전체화면(사진)',
        file_tab_type_2:'동영상',
        file_tab_type_3:'사진',
        file_tab_vertical_type_3:'3개의 분할 화면(위 그림)',
        file_tab_vertical_type_4:'3개의 분할 화면(아래 그림)',
        file_tab_vertical_type_5:'두 개의 분할 화면(아래 그림)',
        file_tab_horizontal_type_3:'3분할 화면(왼쪽 그림)',
        file_tab_horizontal_type_4:'3분할 화면(오른쪽 그림)',
        file_upload:'파일 업로드',
        file_upload_user:'사용자 업로드',
        file_upload_ing:'업로드 중...',
        file_upload_success:'성공적으로 업로드',
        file_upload_fail:'업로드 실패',
        file_upload_confirm:'업로드 확인',
        file_upload_max_count:'매번 최대 {t}개의 파일 업로드',
        file_upload_size:'각 파일 최대 {t}M',
        file_upload_pixel_size:'권장 크기:',
        file_upload_format:'파일 형식:{t}',
        file_upload_format_error:'{t} 형식으로 파일을 업로드하세요.',
        file_upload_filename:'파일 이름에는 특수 기호를 사용할 수 없습니다.',
        file_release:'릴리스 파일',
        file_release_select:'파일을 선택',
        file_release_select_num:'선택된 총계 {t}',
        file_release_form_team_id_label:'스크린 유형',
        file_release_form_ping_label:'선택 화면',
        file_release_form_palytype_label:'플레이 모드',
        file_release_form_display_true:'예',
        file_release_form_display_false:'아니요',
        file_release_form_button_add_video:'비디오 추가',
        file_release_form_button_add_img:'사진 추가',
        file_release_form_button_add:'파일 추가',
        file_release_form_placeholder_video:'동영상을 선택하세요',
        file_release_form_placeholder_img:'사진을 선택해주세요',
        file_release_confirm:'출시 확인',
        mac_confirm_batch_delete:'삭제',
        mac_confirm_batch_replace_user:'사용자 교체',
        mac_confirm_batch_set_up_expire:'유효한 날짜 설정',
        mac_title_click_select_user:'사용자 선택',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'온라인',
        mac_list_mac_status_lx:'오프라인',
        mac_list_mac_load_status_done:'성공적으로 다운로드',
        mac_list_mac_load_status_waiting:'다운로드 대기 중',
        mac_list_mac_expire:'만료됨',
        mac_list_mac_expire_time:'만료 날짜',
        mac_list_mac_update_user:'업데이트 연산자',
        mac_list_export_name:'장치 MAC 내보내기 {t}.xls',
        mac_import_template_name:'장치 일괄 가져오기 template.xls',
        mac_import_template_button_name:'일괄 추가 장치',
        mac_menu_button_clean:'정리',
        mac_menu_button_batch_delete:'삭제',
        mac_menu_button_batch_replace_user:'사용자 교체',
        mac_menu_button_batch_replace_user_title:'사용자로 이동',
        mac_menu_button_download_template:'템플릿 다운로드',
        mac_menu_button_set_expire:'유효한 날짜 설정',
        mac_menu_button_set_expire_select_user:'사용자 선택',
        mac_menu_button_set_expire_select_device:'장치 선택',
        mac_menu_button_set_expire_select_day:'날짜 선택',
        mac_scancode_title:'코드 스캔',
        mac_scancode_upload_title:'QR코드 업로드',
        mac_scancode_result:'QR코드를 상자에 넣으면 자동으로 스캔됩니다',
        mac_scancode_identify:'인식에 오류가 있음',
        user_register_page_title:'등록된 사용자',
        user_register_download_file_name:'레지스터 초대 코드.xls',
        user_register_page_code_error:'잘못된 초대 코드'
    }
}