// xxx.js 越南语文件
export default {
    app: {
        button:{
            logout: 'Đăng xuất',
            submit: 'gửi',
            confirm: 'xác nhận',
            cancel: 'hủy',
            forbidden:'bị vô hiệu hóa',
            enable:'kích hoạt',
            dialog_confirm_delete_title:'Xác nhận xóa?',
            userpopover_delete_now:'Xóa ngay',
            userpopover_change_password:'Thay đổi mật khẩu',
            userpopover_delete_user:'Xóa người dùng',
            userpopover_forbidden:'bị vô hiệu hóa',
        },
        title: 'Hệ thống quản lý quảng cáo',
        home: 'trang đầu',
        homenotic: 'Bạn có một lô thiết bị sắp hết hạn sử dụng, nếu muốn tiếp tục sử dụng, vui lòng liên hệ với người bán! Thiết bị hết hạn sử dụng bao gồm:',
        user_manage: 'Quản lý người dùng',
        mac_manage: 'Quản lý thiết bị',
        ad_manage: 'Quản lý quảng cáo',
        fie_manage: 'Quản lý tập tin',
        refresh_loosing_text: 'Thả ra để làm mới...',
        refresh_loading_text: 'Đang tải...',
        required_username: 'Vui lòng điền tên người dùng',
        min_username: 'Vui lòng nhập tên người dùng có nhiều hơn {t} ký tự',
        placeholder_username: 'ên người dùng',
        add_username: 'Thêm người dùng',
        required_password: 'Vui lòng điền mật khẩu',
        min_password: 'Vui lòng nhập mật khẩu nhiều hơn {t} ký tự',
        placeholder_password: 'mật khẩu',
        required_confirm_password: 'Vui lòng điền mật khẩu xác nhận',
        placeholder_confirm_password: 'Xác nhận mật khẩu',
        two_password:'Hai mật khẩu không nhất quán',
        edit_password:'Chỉnh sửa mật khẩu',
        required_yzm: 'Vui lòng điền mã xác minh',
        placeholder_yzm: 'Mã xác minh',
        user_count: 'Thống kê người dùng',
        mac_count: 'Thống kê thiết bị',
        ad_count: 'Thống kê quảng cáo',
        resources_count: 'thống kê tài nguyên',
        create_time:'Thời gian tạo',
        not_more:'Không còn nữa',
        search:'Vui lòng nhập từ khóa tìm kiếm',
        search_user:'Vui lòng nhập tài khoản tìm kiếm',
        search_mac:'Vui lòng nhập địa chỉ MAC tìm kiếm',
        check_select_max:'Đã đạt mức tối đa',
        userpopover_delete_user_confirm:'Bạn có xác nhận việc xóa người dùng ({u}) không?',
        userpopover_placeholder_forbidden_note:'Xin hãy nhập ghi chú bị cấm',
        user_list_button_dialog_title:'Điều khiển nút',
        user_list_button_dialog_note:'Chuyển đổi hay không?',
        ad_list_tab_list_total:'Tổng cộng {t} quảng cáo được tìm thấy',
        ad_list_tab_list_card_title:'Tổng cộng {m} video, {i} ảnh',
        ad_list_tab_list_card_type:'Loại: {t}',
        ad_list_tab_list_card_play_type:'Chế độ chơi: {t} {y}',
        ad_list_tab_list_card_mac:'Máy quảng cáo: tổng cộng có {t} đơn vị',
        ad_list_tab_list_card_day:'Ngày phát hành: {t}',
        ad_show:'Hỗ trợ hiển thị khác nhau',
        ad_not_show:'Không hỗ trợ hiển thị khác nhau',
        ad_host_ping:'Màn hình chính',
        ad_deputy_ping:'màn hình phụ',
        ad_complete_ping:'toàn màn hình',
        ad_two_screens:'hai màn hình',
        ad_three_screen:'ba màn hình',
        ad_divide_ping:'chia màn hình',
        ad_class_ping_vertical:'Màn hình dọc',
        ad_class_ping_horizontal:'Màn hình ngang',
        ad_sort:'sắp xếp',
        file_del:'Xóa hàng loạt',
        file_tab_type_1:'Hình ảnh toàn màn hình',
        file_tab_type_2:'Video',
        file_tab_type_3:'hình ảnh',
        file_tab_vertical_type_3:'Ba ảnh màn hình (trên cùng)',
        file_tab_vertical_type_4:'Ba ảnh màn hình (bên dưới)',
        file_tab_vertical_type_5:'Hai ảnh màn hình (dưới)',
        file_tab_horizontal_type_3:'Ba ảnh màn hình (trái)',
        file_tab_horizontal_type_4:'Ba ảnh màn hình (phải)',
        file_upload:'Tải tập tin lên',
        file_upload_user:'tải người dùng lên',
        file_upload_ing:'Đang tải lên...',
        file_upload_success:'Tải lên thành công',
        file_upload_fail:'Tải lên không thành công',
        file_upload_confirm:'Xác nhận tải lên',
        file_upload_max_count:'Không thể tải lên quá {t} tệp mỗi lần',
        file_upload_size:'Yêu cầu về kích thước tệp: tối đa không quá {t}M',
        file_upload_pixel_size:'Kích thước được đề xuất:',
        file_upload_format:'Định dạng tệp: {t}',
        file_upload_format_error:'Vui lòng tải lên tệp định dạng {t}',
        file_upload_filename:'Lưu ý: Tên file không được có ký hiệu đặc biệt! ',
        file_release:'tập tin phát hành',
        file_release_select:'Chọn tập tin',
        file_release_select_num:'Tổng số lựa chọn {t}',
        file_release_form_team_id_label:'loại màn hình',
        file_release_form_ping_label:'Màn hình lựa chọn',
        file_release_form_palytype_label:'Phương pháp phát lại',
        file_release_form_display_true:'có',
        file_release_form_display_false:'Không',
        file_release_form_button_add_video:'Thêm video',
        file_release_form_button_add_img:'Thêm ảnh',
        file_release_form_button_add:'Thêm tập tin',
        file_release_form_placeholder_video:'Vui lòng chọn một video',
        file_release_form_placeholder_img:'Vui lòng chọn một hình ảnh',
        file_release_confirm:'Xác nhận phát hành',
        mac_confirm_batch_delete:'Xóa hàng loạt',
        mac_confirm_batch_replace_user:'Thay thế người dùng hàng loạt',
        mac_confirm_batch_set_up_expire:'Đặt thời hạn hiệu lực',
        mac_title_click_select_user:'Click để chọn người dùng',
        mac_list_mac_delete:'Delete device advertising files',
        mac_list_mac_status_zx:'trực tuyến',
        mac_list_mac_status_lx:'ngoại tuyến',
        mac_list_mac_load_status_done:'Tải xuống hoàn tất',
        mac_list_mac_load_status_waiting:'Đang chờ tải xuống',
        mac_list_mac_expire:'Đã hết hạn',
        mac_list_mac_expire_time:'Thời gian hết hạn',
        mac_list_mac_update_user:'Cập nhật toán tử',
        mac_list_export_name:'Xuất MAC của thiết bị {t}.xls',
        mac_import_template_name:'Mẫu nhập hàng loạt thiết bị.xls',
        mac_import_template_button_name:'Nhập thiết bị hàng loạt',
        mac_menu_button_clean:'Xóa',
        mac_menu_button_batch_delete:'Xóa hàng loạt',
        mac_menu_button_batch_replace_user:'Thay thế người dùng hàng loạt',
        mac_menu_button_batch_replace_user_title:'Hãy chọn chuyển sang người dùng',
        mac_menu_button_download_template:'Tải xuống mẫu',
        mac_menu_button_set_expire:'Đặt ngày hết hạn',
        mac_menu_button_set_expire_select_user:'Chọn người dùng',
        mac_menu_button_set_expire_select_device:'Chọn thiết bị',
        mac_menu_button_set_expire_select_day:'Chọn ngày',
        mac_scancode_title:'Quét mã',
        mac_scancode_upload_title:'Tải lên mã QR',
        mac_scancode_result:'Đặt mã QR vào hộp và quét tự động',
        mac_scancode_identify:'Nhận dạng sai',
        user_register_page_title:'Người dùng đã đăng ký',
        user_register_download_file_name:'Đăng ký mã mời.xls',
        user_register_page_code_error:'Mã mời không hợp lệ'
    }
}